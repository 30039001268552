.range {
  position: relative;
  width: 100%;
}

.range::after {
  content: '';
  width: 100%;
  height: 10px;
  border-radius: 10px;
  background: #f2f4f4;
  display: block;
}

.range-slide {
  position: absolute;
  width: 100%;
  background: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  left: 0;
  top: -3px;
  z-index: 9;
  outline: none;
  opacity: 0;
}

.range-slide::-ms-expand {
  display: none;
}

.range-slide::-ms-clear {
  display: none;
}

.range-slide::-webkit-slider-thumb {
  width: 35px;
  height: 35px;
  margin: -3px 0 0 -3px;
  cursor: pointer;
  -webkit-appearance: none;
  appearance: none;
}

.circle {
  position: absolute;
  top: -7px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.07);
  border: solid 2px #dbdbdb;
  background: white;
  display: inline-block;
  transition: 0.2s;
}

.range-value {
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  width: 20%;
  height: 10px;
  border-radius: 8px 0 0 8px;
  transition: 0.2s;
}
