.uploadWidget__details {
  background-color: #ff8b22;
}

p {
  font-size: 20px;
}

.container {
  background-color: rgb(192, 192, 192);
  margin-top: 10px;
}

.skill {
  width: 0%;
  color: white;
  background-color: transparent;
  height: 4px;
  text-align: right;
  font-size: 1px;
  border-radius: 15px;
}
.uploadData {
  flex-grow: 1;
  color: black;
  padding: 1%;
  text-align: right;
  font-size: 16px;
  border-radius: 0px;
}

.progressData {
  /* background-color: white; */
  width: 50%;
  color: black;
  padding: 1%;
  text-align: left;
  font-size: 16px;
  border-radius: 0px;
}
.containerDiv {
  display: flex;
  background: #f6f7f9;
}
.videoTitle {
  width: 50%;
}
.crossIcon {
  flex-grow: 1;
}
.btn {
  position: relative;
  top: 5px;
  left: 20px;
}
.pd30 {
  padding: 0px 30px;
  width: 532px;
}

.header {
  position: absolute;
  top: 32px;
  padding: 0 32px;
  height: 24px;
  width: 528px;
  font-size: 18px;
  line-height: 1.33;
  color: #272a32;
}
