.input-range__slider {
  background: #676b70;
  cursor: e-resize;
  display: block;
  height: 100%;
  position: absolute;
  width: 4px;
}
.input-range__slider:active {
  transform: scale(1.1);
}
.input-range__slider:focus {
  box-shadow: 0 0 0 5px rgba(63, 81, 181, 0.2);
}
.input-range--disabled .input-range__slider {
  background: #cccccc;
  border: 1px solid #cccccc;
  box-shadow: none;
  transform: none;
}

.input-range__slider-container {
  height: 100%;
  top: 0;
  cursor: e-resize;
}

.input-range__label {
  display: none;
  /*
  color: #aaaaaa;
  font-family: "Helvetica Neue", san-serif;
  font-size: 0.8rem;
  transform: translateZ(0);
  white-space: nowrap; */
}
/*
.input-range__label--min,
.input-range__label--max {
  bottom: -1.4rem;
  position: absolute; }

.input-range__label--min {
  left: 0; }

.input-range__label--max {
  right: 0; }

.input-range__label--value {
  position: absolute;
  top: -1.8rem; }

.input-range__label-container {
  left: -50%;
  position: relative; }
  .input-range__label--max .input-range__label-container {
    left: 50%; }
*/
.input-range__track {
  cursor: pointer;
  display: block;
  height: 100%;
  position: relative;
}

.input-range__track--background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.input-range__track--active {
  cursor: pointer;
  background: #b7bfcf;
}

.input-range {
  height: 100%;
  position: relative;
  width: 100%;
}
