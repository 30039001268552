.email-builder-wrapper {
  width: 100%;
}

.email-builder-wrapper .rdw-editor-toolbar {
  width: 100%;
  height: auto;
  background: none;
  padding: 0;
  border: 0;
  margin: 0 0 8px;
  gap: 4px;
}

.email-builder-wrapper .rdw-inline-wrapper {
  margin: 0;
  gap: 4px;
  height: 24px;
}

.email-builder-wrapper .rdw-text-align-wrapper {
  margin: 0;
  gap: 4px;
  height: 24px;
}

.email-builder-wrapper .toolbar-custom-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  background: #ffffff;
  border: 1px solid #e1e2e5;
  border-radius: 4px;
  padding: 0;
  margin: 0;
}

.email-builder-wrapper .toolbar-custom-dropdown {
  width: 40px;
  height: 24px;
  background: #ffffff;
  border: 1px solid #e1e2e5;
  border-radius: 4px;
  padding: 0;
  margin: 0;
}

.email-builder-wrapper .rdw-dropdown-carettoopen {
  top: 40%;
  right: 20%;
  border-top: 4px solid #9297a2;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
}

.email-builder-wrapper .rdw-dropdown-carettoclose {
  top: 40%;
  right: 20%;
  border-bottom: 4px solid #9297a2;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
}

.email-builder-wrapper .rdw-dropdown-optionwrapper {
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.email-builder-wrapper .rdw-dropdown-optionwrapper .toolbar-custom-icon {
  border: 0;
}

.email-builder-wrapper .rdw-link-modal {
  right: 0;
  left: initial;
}

.email-builder-wrapper figure {
  margin: 15px 0 0;
}

.email-builder-wrapper .rdw-image-imagewrapper img {
  max-width: 100%;
}

.email-builder-wrapper .rdw-image-alignment-options-popup {
  display: none;
}

.rdw-option-wrapper.toolbar-custom-icon:hover,
.toolbar-custom-icon .rdw-option-wrapper:hover,
.toolbar-custom-dropdown.rdw-dropdown-wrapper:hover,
.rdw-option-wrapper.toolbar-custom-icon:focus,
.toolbar-custom-icon .rdw-option-wrapper:focus,
.toolbar-custom-dropdown.rdw-dropdown-wrapper:focus,
.rdw-option-active:focus,
.rdw-option-active:hover,
.rdw-option-active,
.rdw-dropdown-optionwrapper:hover {
  box-shadow: none;
}

.email-builder-wrapper .toolbar-custom-icon.rdw-option-active {
  border: 1px solid #001b53;
}
