.automotive-wrapper .rdw-option-wrapper {
  margin: 0 8px 0 0;
}
.automotive-wrapper .rdw-mention-link {
  text-decoration: none;
  background: #f2f4f6;
  border-radius: 5px;
  padding: 4;
}
.automotive-wrapper .rdw-suggestion-wrapper {
  position: relative;
  background: #ffffff;
}
.automotive-wrapper .rdw-suggestion-dropdown {
  position: absolute;
  display: flex;
  flex-direction: column;
  min-width: 300px;
  max-height: 300px;
  overflow: auto;
  z-index: 100;
  background: #ffffff;
  border: 1px solid #f7f8fa;
  box-sizing: border-box;
  border-radius: 5px;
  cursor: pointer;
}
.automotive-wrapper .rdw-editor-main {
  height: auto;
  min-height: 100px;
  max-height: none;
  box-sizing: border-box;
}
.automotive-wrapper .rdw-editor-toolbar {
  padding: 0;
  border: none;
  display: flex;
  justify-content: flex-start;
  background: none;
  flex-wrap: wrap;
  font-size: 15px;
  margin-bottom: 8px;
  user-select: none;
}
.automotive-wrapper.snippet-modal .rdw-editor-toolbar {
  margin-bottom: 6px;
}
.automotive-wrapper.service-page-additional-text-input .rdw-editor-toolbar {
  position: absolute;
  margin-top: 15px;
  margin-left: 15px;
  justify-content: flex-start;
}
.automotive-wrapper .add-content-link:after {
  content: 'Link';
  font-size: 15px;
  display: inline-block;
  margin-left: 6px;
}
.automotive-wrapper .add-content-unlink:after {
  content: 'Unlink';
  font-size: 15px;
  display: inline-block;
  margin-left: 6px;
}
.automotive-wrapper .rdw-option-wrapper.toolbar-custom-icon,
.automotive-wrapper .toolbar-custom-icon .rdw-option-wrapper {
  padding: 6px 12px;
  background: #f2f4f6;
  border: 1px solid rgba(0, 27, 83, 0.05);
  border-radius: 5px;
  color: var(--primary);
  font-family: Work Sans;
  font-weight: 600;
  cursor: pointer;
  margin-bottom: 0;
}
.toolbar-link-icon {
  color: var(--primary);
}
.toolbar-bold-icon > img {
  mask: url('assets/icons/textEditor/bold.svg');
  background-color: var(--primary);
  mask-repeat: no-repeat;
  mask-position: center;

  mask-size: 100%;
  height: 18px;
}
.toolbar-italic-icon > img {
  mask: url('assets/icons/textEditor/italic.svg');
  background-color: var(--primary);
  mask-repeat: no-repeat;
  mask-position: center;
  height: 18px;
}
.toolbar-underline-icon > img,
.toolbar-underline-icon > a > img {
  mask: url('assets/icons/textEditor/underline.svg');
  background-color: var(--primary);
  mask-repeat: no-repeat;
  mask-position: center;
  height: 18px;
}
.toolbar-remove-icon > img {
  mask: url('assets/icons/textEditor/remove.svg');
  background-color: var(--primary);
  mask-repeat: no-repeat;
  mask-position: center;
  height: 18px;
  mask-size: 14px;
}
.toolbar-link-icon > img,
.toolbar-link-icon > a > img {
  mask: url('assets/icons/textEditor/link.svg');
  background-color: var(--primary);
  mask-repeat: no-repeat;
  mask-position: center;
  height: 18px;
}
.toolbar-unlink-icon > img {
  mask: url('assets/icons/textEditor/unlink.svg');
  background-color: var(--primary);
  mask-repeat: no-repeat;
  mask-position: center;
  height: 18px;
}
.toolbar-align-center-icon > img {
  mask: url('assets/icons/textEditor/textAlignCenter.svg');
  background-color: var(--primary);
  mask-repeat: no-repeat;
  mask-position: center;
  height: 18px;
}
.toolbar-align-left-icon > img {
  mask: url('assets/icons/textEditor/textAlignLeft.svg');
  background-color: var(--primary);
  mask-repeat: no-repeat;
  mask-position: center;
  height: 18px;
}
.toolbar-align-right-icon > img {
  mask: url('assets/icons/textEditor/textAlignRight.svg');
  background-color: var(--primary);
  mask-repeat: no-repeat;
  mask-position: center;
  height: 18px;
}
.toolbar-bullet-list-icon > img {
  mask: url('assets/icons/textEditor/bulletList.svg');
  background-color: var(--primary);
  mask-repeat: no-repeat;
  mask-position: center;
  height: 18px;
}
.toolbar-eyedropper-icon > img {
  mask: url('assets/icons/textEditor/eyeDropper.svg');
  background-color: var(--primary);
  mask-repeat: no-repeat;
  mask-position: center;
  height: 18px;
}
.rdw-dropdown-carettoopen {
  border-top-color: var(--primary) !important;
}
.rdw-dropdown-carettoclose {
  border-bottom-color: var(--primary) !important;
}
.small-icon > a > img,
.small-icon > img {
  mask-size: 80%;
}
.small-icon > div > img {
  mask-size: 100%;
}
.large-icon > img {
  mask-size: 150%;
}
.medium-icon > img {
  mask-size: 110%;
}
.toolbar-custom-link-dropdown-option .rdw-dropdown-optionwrapper {
  overflow-y: hidden;
  min-width: 120px;
}
.toolbar-custom-link-dropdown-option .rdw-link-dropdownoption {
  padding-left: 10px;
  justify-content: normal;
}
.toolbar-custom-link-dropdown-option {
  position: relative;
  margin-right: 8px;
}
.rdw-option-wrapper.toolbar-custom-icon:hover,
.toolbar-custom-icon .rdw-option-wrapper:hover,
.rdw-option-wrapper.toolbar-custom-icon:focus,
.toolbar-custom-icon .rdw-option-wrapper:focus,
.rdw-option-active:focus,
.rdw-option-active:hover,
.rdw-option-active {
  box-shadow: none;
}
.automotive-wrapper .rdw-option-wrapper.toolbar-custom-icon.rdw-option-active {
  border: 1px solid #001b53;
}

.automotive-wrapper .DraftEditor-root .DraftEditor-editorContainer {
  z-index: 0;
}
.automotive-wrapper .rdw-inline-wrapper {
  margin-bottom: 0;
}
.automotive-wrapper .rdw-remove-wrapper {
  margin-bottom: 0;
}
.automotive-wrapper .rdw-link-wrapper {
  margin-bottom: 0;
}
.automotive-wrapper.disable-formatting-buttons .rdw-inline-wrapper,
.automotive-wrapper.disable-formatting-buttons .toolbar-custom-icon,
.automotive-wrapper.disable-formatting-buttons .toolbar-custom-fontSize,
.automotive-wrapper.disable-formatting-buttons .toolbar-custom-fontFamily,
.automotive-wrapper.disable-formatting-buttons .add-content-link {
  opacity: 0.4;
  pointer-events: none;
}
